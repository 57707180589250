@import 'common-scss/colors';


body {
  overflow-x: hidden;
  height:100%!important;
  min-height: 100vh;

}
#root{
  height: 100%;
  min-height: 100vh;
}
.M-txt{
  margin-top: 5px!important;
}
.Div-gris h1{
  text-transform: uppercase!important;
}
input[type=checkbox]{
  height: 16px;
  display: flex;
  width: 16px;
  margin-right: 0px;
}
.mt-11{
  margin-top: -11px;
}
.MuiTable-root,.MUIDataTable-responsiveScrollMaxHeight-597{
  overflow-x: scroll!important;
  overflow-y: hidden!important;


}
.Small-dropzone-name-img{
  position: absolute;
  bottom: -19px;
  color: black;
  width: 100%;
  display: block;
  left: 0;
  font-size: 12px;
  text-align: center;
}
.Overflow-x-scroll{
  overflow-x: scroll!important;
}
* {
  font-family: 'Lato','Open Sans', sans-serif!important;
}
.D-bm{
  margin-top: 15px;
  display: block;
}
button:focus {
  outline: none!important;
}

.P-absolute-0{
  position:absolute;
  bottom:0
}
.Small-center{
  width: 100%;
  text-align: center;
  display: block;
  margin-bottom: 7px;

}
.App{
  position: relative;
  height:100%!important;
  min-height: 100vh;
}
a {
  font-size: 15px;
  color: $gray;

  &:hover {
    text-decoration: none;
    color: initial !important;
  }
}
#transition-modal-description{
  margin-bottom: 19px!important;
}
h2 {
  font-size: 25px !important;
}

.Login-div {
  min-height: 420px;
}

.Txt-sm {
  font-size: 13px;
}

.Txt-md {
  font-size: 14px;
}

.Txt-lg {
  font-size: 16px
}

.Txt-green {
  color: $green;
}

.Txt-gray {
  color: $gray;
}

.Txt-dgray {
  color: $dark-gray;
}

.Bg-blue {
  background: $primary-color;
}
.Idioma{

    font-weight: 700!important;

}
.Bg-dark-blue {
  background: $dark-blue;
}

.p-absolute {
  position: absolute;
}

.p-relative {
  position: relative;
}

hr {
  height: 1px;
  background: $green;
  width: 100%;

}
.M-h-310{
  min-height: 310px;
}
.Border-red {
  border-color: red !important;
}

.T-uppercase input {
  text-transform: uppercase !important;
}

.Txt-white {
  color: white;
}

.Txt-red {
  color: red
}

.Txt-error-form {
  color: red !important;
  margin-top: -7px !important;
}

.Bg-Transition {
  width: 100%;
  height: 100%;
  background: linear-gradient(-45deg, $n-gray, $n-gray, #fff, #fff);
  background-size: 600% 100%;
  animation: gradient 10s ease infinite;
  animation-direction: alternate;
  min-height: 100vh;
}
.PrivateTabIndicator-colorSecondary-114{
background-color: $green!important;
}
.MuiRadio-colorSecondary.Mui-checked{
color: $green!important;
}
.MuiTab-wrapper{
  color: $dark-gray;
}
.Bg-Transition-header {
  width: 100%;
  height: 100%;
  background: linear-gradient(-45deg, $n-gray, $n-gray, #fff, #fff);
  background-size: 600% 100%;
  animation: gradient 10s ease infinite;
  animation-direction: alternate;
}

@keyframes gradient {
  0% {
    background-position: 0%
  }
  100% {
    background-position: 100%
  }
}

.Cursor-pointer {
  cursor: pointer;
}

.White-card {
  background: transparent;
  border-radius: 5px;
  max-width: 850px;
  /*box-shadow: 0px 1px 15px 1px rgba(62, 57, 107, 0.07);*/
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px;

  &.Login {
    max-width: 750px;

  }

}


.Logo {
  max-width: 200px;
}

.Logo-login {
  background-color: $gray !important;
  width: 45px !important;
  margin-bottom: 20px;
  height: 45px !important;
}

.Padding-seccion-principal {
  padding-top: 90px ;
  padding-bottom: 220px;
}
.Vh-100{
  height: 100vh;
}
.Titulo-pagina {
  color: white;
  background-color: #09008b;
  text-align: center;
  margin-top: 0px;
  margin-bottom: 50px;
  font-size: 28px;
  padding: 19px 10px;
}

.M-form-titulo{
  margin-top: 55px;
}
.Subtitulo1 {
  color: $dark-gray !important;
  font-size: 22px !important;

}

.Subtitulo2 {
  color: $dark-gray !important;
  font-size: 22px !important;
  font-weight: 700!important;
}
.Titulo2 {
  color: $dark-gray !important;
  font-size: 24px !important;
  font-weight: 700!important;

}

.Caja-gris{
  width: 100%;
  height: 230px;
  border-radius: 6px;
  border: 2px solid $l-gray
}

.Titulo3 {
  color: $dark-gray !important;
  font-size: 19px !important;
  font-weight: 600!important;
}
.w-33 {
  width: 33% !important;
  padding-left: 15px !important;
  &:first-child{
    padding-left: 0!important;

  }
}
.DayPickerInput{
  input{
    width: 85%;
    border-radius: 8px;
    border: 1px solid $l-gray;
    padding: 5px 10px;
    text-align: center;

    &:active{
      border: 1px solid $l-gray;
      outline: none!important;
    }
    &:focus{
      border: 1px solid $l-gray;
      outline: none!important;
    }
    &:hover{
      border: 1px solid $l-gray;
      outline: none!important;
    }
  }
}

.DayPickerInput-Overlay{
  z-index: 10000000!important;
}
.MuiIconButton-colorSecondary:hover{
background-color: transparent!important;
}
.DayPicker-Caption div{
  text-transform: capitalize!important;
}
.Ico-g {
  width: 100px;
}

.Ico-p {
  width: 35px;
}
.MuiTabs-indicator{
  background-color: $green!important;
}
.Txt-link-login{
  max-width: 300px!important;
  display: block;
  width: 100%;
}
.Mui-selected{
  border-bottom: 2px solid $green;
}

.Btn, .MuiButton-containedPrimary {
  max-width: 300px;
  width: 300px;
  padding: 0px 16px;
  font-size: 1rem !important;
  min-width: 64px;
  box-sizing: border-box;
  transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms, border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;
  font-family: "Roboto", "Helvetica", "Arial", sans-serif !important;
  font-weight: 400 !important;
  line-height: 1.75;
  border-radius: 4px;
  letter-spacing: 0.02857em;
  text-transform: uppercase;
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  padding-top: 2px;
  transition: 0.3s;
  span {
    font-weight: 500 !important;
    font-size: 1.05rem !important;

  }


  &:hover {
    background-color: $gray;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  }

  &.Btn_green {
    color: #fff;
    background-color: $green;

    &:hover{
      color: #fff!important;
      background-color: $green-btns-hover;
    }
  }

  &.Btn_gray {
    color: #fff;

    background-color: $gray-btns;

    &:hover {
      color: #fff;
      background-color: $gray-btns-hover;
    }
  }

  .Grafico-contador2 div{
    display:none!important;
  }

  &.Btn-transparent-border-gray {
    background-color: transparent;
    color: $dark-gray;
    box-shadow: none;
    border: 2px solid $l-gray;

    &:hover {
      background-color: $gray-transparent-btns-hover;
      border: 2px solid $l-gray;

    }

  }


}

.Margin-input-r {
  margin-right: 30px;
}


.Btn span {
  cursor: pointer;
  display: inline-block;
  position: relative;
  transition: 0.5s;

}


.Btn span:after {
  content: '\00bb';
  position: absolute;
  opacity: 0;
  top: 0;
  right: -20px;
  transition: 0.5s;
}

.Btn:hover span {
  padding-right: 25px;
}

.Btn:hover span:after {
  opacity: 1;
  right: 0;
}

.Btn-transparent-border-green {
  text-align: center;
  background-color: transparent;
  border: 1px solid $green;
  color: $green;
  font-size: 16px;

  width: 300px;
  min-width: 100%;
  border-radius: 4px;
  transition: 0.5s;
  padding: 6px 15px;
}
.Btn-transparent-border-green-disabled {
  text-align: center;
  background-color: $l-gray;
  border: 1px solid $l-gray;
  color: black;
  font-size: 16px;
  width: 300px;
  min-width: 100%;

  cursor:initial!important;
  cursor:inherit!important;
  border-radius: 4px;
  transition: 0.5s;
  padding: 6px 15px;
}
.Borde-gris{
  border-radius: 10px;
  border: 2px solid $l-gray;
  display: inline;
  padding: 8px 60px;
  font-weight: 600;
}
.Titulo-pag-grafico{
  margin: 2.5rem 0;
  margin-top: 3rem;
  text-align: center;
  font-weight: 600;
}

.Btn-transparent-border-green:hover {
  background-color: rgba(107, 167, 156, 0.18);
  border: 1px solid $green;
  color: $green!important;


}

.Btn-small {
  width: 100px;
  padding: 5px 10px;
  font-size: 13px;
}


button .MuiCircularProgress-root {
  width: 22px !important;
  height: 22px !important;

}

#__react-alert__ > div > div > div {
  width: auto !important;
  max-width: 500px;
}
.Bg-gray{
  background-color: $l-gray;
}
svg div{
  display:none!important
}
/*PARA ERRORES FORMULARIOS*/
.Error-input-border {
 /* border-bottom: 1px solid red !important;
*/
  &.NoRadius {
    border-radius: 7px !important;
    border-bottom: 2px solid red !important;

  }

  fieldset {
    border-color: red !important;
  }

}

.Txt-lgray {
  color: $l-gray
}

.Loading-div {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.14);
}

.MuiFormControl-marginNormal {
  margin-top: 0 !important;
}


/*FORMS*/
.Row-flex {
  display: flex;
  align-items: center;
  margin: 45px 0;
  width: 100%;
  justify-content: space-between;

  .Btn {
    width: 100%;
  }


  > div {
    width: 48%;
  }

  &.Row_input_btn{
    > div {
      width: 100%!important;

    }
    & .Btn{
      margin-left: 50px;
    }
  }
}


/**/
.Movil-menu{
  display:none!important;
}

@media (max-width: 768px) {
  .MuiPaper-root>div{
    overflow-x: scroll!important;
  }
  .Escritorio{
    display:none!important;
  }
  .D-f-menu{
    display: flex;
  justify-content: center;
align-items: center;
  }
  .Movil-menu{
    display:flex!important;
    flex-direction: column!important;
  }
  .Modal-Grafico{
    max-height: 100vh!important;
  }
  .Ver-grafica{
    overflow-x: scroll;
  }
  #transition-modal-description{
    .Grafico-div{
      >div{
        width: 1100px!important;
        overflow-x: scroll!important;
      }
    }
  }
  .Grafico-div {
    width: 1100px!important;
    height: 550px;
    overflow-x: scroll!important;

  }
  .Filtro-avanzado-consumo{
    .MuiFormGroup-row{
      justify-content: center!important;
    }
    .DayPickerInput{
      input{
        width: 100%!important;
      }
    }
    .No-pad{
      padding-left: 0!important;
    }
  }
  .Overflow-x-scroll-m{
    overflow-x: scroll!important;
  }
  .Btn{
    max-width: 100%!important;
  }
  .Txt-link-login{
    max-width: 100%!important;
  }
  .Btn-movil{
    margin: 0!important;
    margin-top: 25px!important;
    margin-bottom: 25px!important;
  }
  .mt-11{
    margin-top: 0;
  }
  .w-100-m {
    width: 100% !important;
  }
  .flex-column-m {
    flex-direction: column !important;
  }
  .MuiFormControl-fullWidth {
    margin: 0px 0 !important;
  }
  .Row-flex div {

  }
  .Subtitulo1, .Subtitulo2 {
    text-align: center !important;
  }
  .w-33 {
    width: 100% !important;
  }
  .m-flex-column {
    flex-direction: column !important;
  }
  .w-m-100 {
    width: 100% !important;
  }

  /*FORMS*/
  .Row-flex {
    flex-direction: column;
      margin: 25px 0;
    .MuiTextField-root {
      max-width: 100% !important;
    }

    > div {
      max-width: 100% !important;
      width: 94%;
      margin-bottom: 20px;
    }
  }
  .White-card{
    padding-left: 20px;
    padding-right: 20px;
  }
  .Padding-seccion-principal {
    padding-top: 30px;
    padding-bottom: 220px;
  }
  .Form-signup {
    .Div-botones-form{
      flex-direction: column;
    }
    .Div-botones-form button {
      max-width:100%;
      margin-bottom: 20px;
    }
    .Form-signup .MuiFormControl-root {
      margin-bottom: 0px !important;
    }
  }
  .Borde-gris {
    border-radius: 10px;
    border: 2px solid #dddddf;
    display: inline;
    padding: 8px 6px;
    font-weight: 600;
    font-size: 15px !important;
  }
  .w-25{
    width: 100%!important;
    margin-bottom: 15px;
  }
  body,table {
    overflow-x: scroll!important;

  }
}


/*REACT ALERT*/
#__react-alert__ > div > div  {

  animation: moveleft 1s 1 normal forwards!important;

}

@keyframes moveleft {
  from { transform: translateX(200px); }
  to   { transform: translateX(0px); }

}

/*.Alert{
  padding: 30px;
  width: 100%!important;
  &.Alert_success{
    background-color: #1f9b5d!important;
  }
  &.Alert_error{
    background-color: #c62727!important;
  }
}
#__react-alert__ > div > div > div{
  background-color: transparent !important;
  width: auto!important;
  padding-left: 0px!important;
  padding-top: 0px!important;
  margin-right: -15px!important;

  padding-bottom: 0px!important;
  box-shadow: none!important;
  border: none!important;
  span{
    color: white;
    div{
      display: flex;
      align-items: center;

      span{
        font-weight: 600;
        font-size: 14px;
      }
      img{
        width:25px;
        margin-right: 10px;
      }
    }
  }

  svg{
    display: none;
  }
}*/

#__react-alert__ > div > div > div{
  background-color: #f5f5f5 !important;
  width: auto!important;
  padding-left: 20px!important;
  padding-top: 20px!important;
  padding-bottom: 20px!important;
  span{
    color: #000;
    div{
      display: flex;
      align-items: center;

      span{
        font-weight: 600;
        font-size: 14px;
      }
      img{
        width:25px;
        margin-right: 10px;
      }
    }
  }

  svg{
    display: none;
  }
}
/**/