@import '../../../common-scss/colors';


.Ver-grafica-contador{
  height: auto;

  .Col-1t{
 margin-right: 15px;
  }
  .Col-2t{
    margin-left: 15px;

  }

  .R1{
    background-color: $l-gray;
    color: black;
    border-radius: 8px 8px 0 0;
    width: 320px;
    padding: 17px 20px;
    height: 111px;
    display: flex;
    min-width: 100%;
    justify-content: center;
    align-items: center;
    p{
      font-size: 17px;
      padding-left: 0!important;

    }
  }
  .R2{
    border: 2px solid $l-gray;
    color:black;
    font-weight: bold;
    background-color: white;
    font-size: 20px;
    border-radius: 0 0 8px 8px;
    height: 70px;
    display: flex;
align-items: center;
justify-content: center;
    p{
      font-size: 17px;
      padding-left: 0!important;
    }

  }
  .R22{
    border: 2px solid $l-gray;
    background-color: #1f9b5d;
    color : white;
    font-weight: bold;
    font-size: 20px;
    border-radius: 0 0 8px 8px;
    height: 70px;
    display: flex;
align-items: center;
justify-content: center;
  }
  .Icp{
    width: 100%;
    height: 71px;
    text-align: center;
    font-size: 18px;
    color: white;
    text-transform: uppercase;
    display: flex;
    border-radius: 0 0 8px 8px;
    align-items: center;
    justify-content: center;

    font-weight: bold;
  }
  .Icp_desconectado{
    background-color: #c62727;

  }
  .Icp_conectado{
    background-color: #1f9b5d;

  }
  .Icp_ind{
    background-color: $l-gray;
  }
  p{
    padding-left: 25px;
    font-size: 18px;
    padding-top: 10px;
  }

  .MuiTabs-flexContainer{
    justify-content: center;

  }
  .Tabfiltro{

    header{
      background:none!important;
      color: $primary-color
    }
    .MuiBox-root{
     /* border: 1px solid $n-gray!important;*/
      padding: 0!important;
    }
  }

  .Tabfiltro1{
    header{
      background:none!important;
      color: $primary-color;

      .MuiTabs-flexContainer{
        justify-content: center;
      }
    }
    .MuiBox-root{
    border-top: 2px solid $n-gray!important;
    border-bottom: 2px solid $n-gray!important;
    padding: 10px 5px!important;

  }
  }
  .Tabgrafico{
    .MuiBox-root{
      padding: 7px 0px!important;
    }

  }
  .Filtro-avanzado{
    display: flex;
    align-items: flex-end;
    border-top: 2px solid #ebebeb !important;
    border-bottom: 2px solid #ebebeb !important;
    padding: 30px 5px !important;
    /*  .Btn{
        height: 46px;
        width: 173px;
      }*/

    input{
      padding: 5px;
    }
    p{
      margin-bottom: 7px;
    }
  }
}

.Potencia-p{
  padding-left: 5px!important;
  text-align: left!important;
  padding-top: 0!important;
  font-size: 13px!important;
  margin: 0 auto!important;
  display: inline!important;
  position: absolute!important;
  right: 9%!important;
  top: 21px!important;
  width: auto!important;
}
.Potencia-i{
  text-align: center!important;
  font-size: 13px!important;
  margin: 0 auto!important;
  display: block!important;
  width: auto!important;
  padding-top: 5px !important;
  margin-top: 12px !important;
}
.Potencia-i2{
    position: absolute;
    bottom: 4px;
    width: 100% !important;
    display: flex !important;
    margin: 0 auto;
    text-align: center;
    justify-content: center;
    font-size: 11px !important;
}
.Grafico-div{
  width: 99vw;
  height: 550px;
  rect{
    cursor: pointer!important;
  }

  .Titulo-grafico{
    position: absolute;
    top: 10px;
    color: $dark-gray;
    left: 20px;
    font-size: 15px;

  }
  .Energia-total{
   /* position: absolute;*/
    top: 10px;
    color: $dark-gray;
    font-weight: bold;
    right: 20px;
    font-size: 15px;

  }
}
.Grafico-contador{
  height: 500px;
  max-width: 100%;
  width: 500px;
}
.Grafico-contador2{
  height: 150px;
  width: 100%;
  position: relative;
}
.Tabla-Contador{
  margin: 0 auto;
  tr{
    td{
      width: 276px;
      padding: 23px;
      border: 1px solid gray;

    }
    td:first-child{
      background-color: $l-gray;
    }
  }

}

@media (max-width: 768px){
  .Ver-grafica
  {
    .MuiButtonBase-root{
      padding-left: 3px;
    font-size: 14px;
    }
  }
  .Col-1t{
    margin: 0!important
  ;
  }
  .Col-2t{
    margin: 0!important
  ;
    margin-top: 30px!important;

  }
}

